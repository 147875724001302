"use client";

import React from "react";
import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";
import { Button } from "./ui/button";
import { useLocale, useTranslations } from "next-intl";
import { useRouter } from "@/navigation";
import cookie from "js-cookie";

const ToggleLanguage = () => {
  const local = useLocale();
  const router = useRouter();

  return (
    <div
      className="fixed flex h-12 z-50 justify-center items-center  overflow-hidden -right-[16px]  top-36 -rotate-90 rounded-tl-xl rounded-tr-xl border-2 border-primary dark:border-white"
      dir="ltr"
    >
      <Button
        onClick={() => router.replace("/", { locale: "ar" })}
        className={`basis-1/2 px-2 h-full rounded-none rounded-tl-none rounded-bl-none rounded-br-none hover:bg-current/90 ${
          local === "ar"
            ? "bg-primary text-white dark:bg-white dark:text-black"
            : "dark:bg-black dark:text-white bg-white text-primary dark:hover:text-black dark:hover:bg-primary"
        }`}
      >
        <span className="rotate-90">AR</span>
      </Button>
      <Button
        onClick={() => {
          router.replace("/", { locale: "en" });
          console.log("test");
        }}
        className={`basis-1/2 px-2 h-full rounded-none rounded-tr-none rounded-br-none rounded-bl-none hover:bg-current/90 ${
          local === "en"
            ? "bg-primary text-white dark:bg-white  dark:text-black"
            : "dark:bg-black dark:text-white  text-primary bg-white"
        }`}
      >
        <span className="rotate-90">EN</span>
      </Button>
    </div>
  );
};

export default ToggleLanguage;
