import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/online-shop-internal-mintops/components/mode-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/online-shop-internal-mintops/components/nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/var/jenkins_home/workspace/online-shop-internal-mintops/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/online-shop-internal-mintops/components/toggleLanguage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/online-shop-internal-mintops/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/online-shop-internal-mintops/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/online-shop-internal-mintops/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/online-shop-internal-mintops/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/online-shop-internal-mintops/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/joystix.ttf\",\"weight\":\"700\"}],\"variable\":\"--font-joystix\"}],\"variableName\":\"joystix\"}");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/online-shop-internal-mintops/styles/globals.css");
