"use client";

import * as React from "react";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";

import { Button } from "@/components/ui/button";
// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuTrigger,
// } from "@/components/ui/dropdown-menu";
import { useLocale, useTranslations } from "next-intl";
import { cn } from "@/lib/utils";
// import { set } from "react-hook-form";
// import { Switch } from "./ui/switch";

export function ModeToggle() {
  const { theme, setTheme } = useTheme();
  const local = useLocale();
  const t = useTranslations("Index");

  return (
    <Button
      // variant="default"
      onClick={() => (theme === "dark" ? setTheme("light") : setTheme("dark"))}
      className={cn(
        "peer shadow-[0px_0px_20px] shadow-input border dark:border-white/[0.2] dark:flex-row-reverse inline-flex gap-3 justify-center overflow-hidden px-2 h-14 w-40 shrink-0 hover:bg-current items-center rounded-full border-1 border-primary transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 bg-white dark:bg-background",
        local === "ar" ? "gap-14" : ""
      )}
    >
      <div className="h-10 w-10 flex justify-center items-center rounded-full bg-primary  dark:bg-white">
        {theme === "light" ? (
          <Moon
            className="rotate-90  transition-all dark:rotate-0 text-white"
            width={20}
            height={20}
          />
        ) : (
          <Sun
            className="rotate-0  transition-all dark:-rotate-90 text-primary"
            width={20}
            height={20}
          />
        )}
      </div>

      <span
        className={`${
          local === "en" ? "tracking-[0.4em]" : ""
        } text-xl font-medium text-black dark:text-white`}
      >
        {theme === "light" ? t("light") : t("dark")}
      </span>
      <span className="sr-only">{t("toggle_theme")}</span>
    </Button>
  );
}

// export function ModeToggle() {
//   const { theme, setTheme } = useTheme();
//   const local = useLocale();
//   const t = useTranslations("Index");

//   return (
//     <Switch
//       // variant="default"
//       onClick={() => (theme === "dark" ? setTheme("light") : setTheme("dark"))}
//       className="peer shadow-lg  dark:flex-row-reverse inline-flex gap-2 overflow-hidden px-2 h-14 w-40 shrink-0 cursor-pointer items-center rounded-full border-1 border-primary transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-black data-[state=unchecked]:bg-background"
//     >
//       <span
//         className={`absolute
//         -left-[56px] dark:-left-[62px] text-foreground dark:text-white tracking-widest text-lg font-medium`}
//       >
//         Dark
//       </span>
//       <div
//         className={`h-10 w-10 flex justify-center items-center rounded-full bg-primary  dark:bg-white ${
//           local === "en"
//             ? "-translate-x-3 dark:translate-x-3"
//             : "translate-x-3 dark:-translate-x-12"
//         }`}
//       >
//         {theme === "dark" ? (
//           <Sun
//             className="rotate-0  transition-all dark:-rotate-90 text-primary"
//             width={20}
//             height={20}
//             color="blue"
//           />
//         ) : (
//           <Moon
//             className="rotate-90  transition-all dark:rotate-0 text-white "
//             width={20}
//             height={20}
//           />
//         )}
//       </div>

//       <span className="tracking-widest text-lg font-medium">Light</span>
//       <span className="sr-only">{t("toggle_theme")}</span>
//     </Switch>
//   );
// }
